import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "input"];

  connect() {
    this.toggleInputs();
  }

  toggleInputs() {
    const isChecked = this.checkboxTarget.checked;

    this.inputTargets.forEach((input) => {
      input.disabled = !isChecked;

      if (isChecked) {
        input.classList.remove("disabled");
      } else {
        input.classList.add("disabled");
      }
    });
  }

  checkboxChanged() {
    this.toggleInputs();
  }
}
